<template>
  <div class="loader">
    <div ref="animation">
      <div ref="circles">
        <span class="hide" style="--i: 1;"></span>
        <span class="hide" style="--i: 3;"></span>
        <span class="hide" style="--i: 2;"></span>
      </div>
      <img
          ref="heart"
          src="@/assets/img/Vuyap_heart_blue.svg"
          class="heart heart-inactive"
      />
    </div>
    <v-row v-if="message" justify="center" align="center">
      <v-col cols="12">
        <div class="text--secondary text-center">
          {{ message }}
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'LoaderAnimation',
  props: {
    message: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    loader: null,
    timer: 2500
  }),
  created() {
    requestAnimationFrame(() => {
      const circles = this.$refs.circles?.childNodes
      const { heart } = this.$refs
      if (!this.$refs.animation) {
        return
      }

      // sync animation
      this.$refs.animation.classList.add('animation')
      circles.forEach(child => {
        child.classList.add('circle')
      })

      heart.classList.toggle('heart-active')
      heart.classList.toggle('heart-inactive')

      setTimeout(() => {
        circles.forEach(child => {
          child.classList.remove('hide')
        })
        heart.classList.toggle('heart-active')
        heart.classList.toggle('heart-inactive')
      }, 600)

      // heart animation
      this.loader = setInterval(() => {
        heart.classList.toggle('heart-active')
        heart.classList.toggle('heart-inactive')
        setTimeout(() => {
          heart.classList.toggle('heart-active')
          heart.classList.toggle('heart-inactive')
        }, 600)
      }, this.timer)
    })
  }
}
</script>

<style lang="scss" scoped>
.hide::before {
  background: white !important;
}

.loader {
  width: 100%;
  height: 100px;
  margin: 0 auto;
}

.heart {
  &-inactive {
    transition-duration: 0.3s;
    transform: scale(0);
  }
  &-active {
    transform: scale(1);
    transition-duration: 0.3s;
  }
}

.animation {
  width: 100px;
  height: 100px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 16px;
  animation: spin 2.5s infinite;

  .heart {
    width: 160px;
    top: -20px;
    position: absolute;
    left: -29px;
  }
  .circle {
    display: block;
    position: absolute;
    width: 70px;
    height: 70px;
    left: 15px;
    top: 15px;
    transform: rotate(calc(120deg * var(--i) - 15deg));
    animation: circle-opacity 2.5s ease-in infinite;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 34px;
      height: 34px;
      background: var(--v-primary-base);
      border-radius: 50%;
      animation: circle-size 2.5s linear infinite;
    }
  }
}

@keyframes spin {
  0%,
  30% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes circle-size {
  0% {
    transform: scale(0.85);
  }
  30% {
    transform: scale(0.9);
  }
  80% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.85);
  }
}

@keyframes circle-opacity {
  0%,
  15% {
    opacity: 0.5;
  }
  20%,
  30% {
    opacity: 0;
  }
  50% {
    opacity: calc(0.1 * var(--i));
  }
  75%,
  85% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}
</style>
