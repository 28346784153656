<template>
    <v-img
        :height="height"
        :width="width"
        :src="light ? lightLogo : darkLogo"
        contain
        :class="className"
    />
</template>

<script>
import darkLogo from '@/assets/img/Logo_white.svg'
import lightLogo from '@/assets/img/Logo.svg'

export default {
  name: "GlobalLogo",
  data: () => ({
    darkLogo,
    lightLogo
  }),
  props: {
    className: {type: String, default: ''},
    height: {type: Number, default: 50},
    width: {type: Number, default: 200},
    light: {type: Boolean, default: false}
  }
}
</script>
