import globalStore from '@/store'

export default {
    async fetchContactInfo({commit, state}, id) {
        if (!state.contacts[id]) {
            const response = (await globalStore._vm.$firebase.firestore().doc(`users/${id}/invoices/organisationData`).get()).data() || {}
            commit('addContact', {
                id, data: response || {}
            })
        }

        return state.contacts[id]
    }
}
