module.exports = { // THIS METRICS WAS IN DOCS
    allDimensions: {
        achievementId: 'achievementId',
        adFormat: 'adFormat',
        adSourceName: 'adSourceName',
        adUnitName: 'adUnitName',
        appVersion: 'appVersion',
        audienceId: 'audienceId',
        audienceName: 'audienceName',
        brandingInterest: 'brandingInterest',
        browser: 'browser',
        campaignId: 'campaignId',
        campaignName: 'campaignName',
        character: 'character',
        city: 'city',
        cityId: 'cityId',
        cohort: 'cohort',
        cohortNthDay: 'cohortNthDay',
        cohortNthMonth: 'cohortNthMonth',
        cohortNthWeek: 'cohortNthWeek',
        contentGroup: 'contentGroup',
        contentId: 'contentId',
        contentType: 'contentType',
        country: 'country',
        countryId: 'countryId',
        date: 'date',
        dateHour: 'dateHour',
        dateHourMinute: 'dateHourMinute',
        day: 'day',
        dayOfWeek: 'dayOfWeek',
        defaultChannelGrouping: 'defaultChannelGrouping',
        deviceCategory: 'deviceCategory',
        deviceModel: 'deviceModel',
        eventName: 'eventName',
        fileExtension: 'fileExtension',
        fileName: 'fileName',
        firstSessionDate: 'firstSessionDate',
        firstUserCampaignId: 'firstUserCampaignId',
        firstUserCampaignName: 'firstUserCampaignName',
        firstUserDefaultChannelGrouping: 'firstUserDefaultChannelGrouping',
        firstUserGoogleAdsAdGroupId: 'firstUserGoogleAdsAdGroupId',
        firstUserGoogleAdsAdGroupName: 'firstUserGoogleAdsAdGroupName',
        firstUserGoogleAdsAdNetworkType: 'firstUserGoogleAdsAdNetworkType',
        firstUserGoogleAdsCreativeId: 'firstUserGoogleAdsCreativeId',
        firstUserGoogleAdsKeyword: 'firstUserGoogleAdsKeyword',
        firstUserGoogleAdsQuery: 'firstUserGoogleAdsQuery',
        firstUserMedium: 'firstUserMedium',
        firstUserSource: 'firstUserSource',
        firstUserSourcePlatform: 'firstUserSourcePlatform',
        fullPageUrl: 'fullPageUrl',
        googleAdsAccountName: 'googleAdsAccountName',
        googleAdsAdGroupId: 'googleAdsAdGroupId',
        googleAdsAdGroupName: 'googleAdsAdGroupName',
        googleAdsAdNetworkType: 'googleAdsAdNetworkType',
        googleAdsCreativeId: 'googleAdsCreativeId',
        googleAdsKeyword: 'googleAdsKeyword',
        googleAdsQuery: 'googleAdsQuery',
        groupId: 'groupId',
        hostName: 'hostName',
        hour: 'hour',
        isConversionEvent: 'isConversionEvent',
        itemBrand: 'itemBrand',
        itemCategory: 'itemCategory',
        itemCategory2: 'itemCategory2',
        itemCategory3: 'itemCategory3',
        itemCategory4: 'itemCategory4',
        itemCategory5: 'itemCategory5',
        itemId: 'itemId',
        itemListId: 'itemListId',
        itemListName: 'itemListName',
        itemName: 'itemName',
        itemPromotionCreativeName: 'itemPromotionCreativeName',
        itemPromotionId: 'itemPromotionId',
        itemPromotionName: 'itemPromotionName',
        landingPage: 'landingPage',
        language: 'language',
        languageCode: 'languageCode',
        linkClasses: 'linkClasses',
        linkDomain: 'linkDomain',
        linkId: 'linkId',
        linkText: 'linkText',
        linkUrl: 'linkUrl',
        medium: 'medium',
        method: 'method',
        minute: 'minute',
        mobileDeviceBranding: 'mobileDeviceBranding',
        mobileDeviceMarketingName: 'mobileDeviceMarketingName',
        mobileDeviceModel: 'mobileDeviceModel',
        month: 'month',
        newVsReturning: 'newVsReturning',
        nthDay: 'nthDay',
        nthHour: 'nthHour',
        nthMinute: 'nthMinute',
        nthMonth: 'nthMonth',
        nthWeek: 'nthWeek',
        nthYear: 'nthYear',
        operatingSystem: 'operatingSystem',
        operatingSystemVersion: 'operatingSystemVersion',
        operatingSystemWithVersion: 'operatingSystemWithVersion',
        orderCoupon: 'orderCoupon',
        outbound: 'outbound',
        pageLocation: 'pageLocation',
        pagePath: 'pagePath',
        pagePathPlusQueryString: 'pagePathPlusQueryString',
        pageReferrer: 'pageReferrer',
        pageTitle: 'pageTitle',
        percentScrolled: 'percentScrolled',
        platform: 'platform',
        platformDeviceCategory: 'platformDeviceCategory',
        region: 'region',
        screenResolution: 'screenResolution',
        searchTerm: 'searchTerm',
        sessionCampaignId: 'sessionCampaignId',
        sessionCampaignName: 'sessionCampaignName',
        sessionDefaultChannelGrouping: 'sessionDefaultChannelGrouping',
        sessionGoogleAdsAccountName: 'sessionGoogleAdsAccountName',
        sessionGoogleAdsAdGroupId: 'sessionGoogleAdsAdGroupId',
        sessionGoogleAdsAdGroupName: 'sessionGoogleAdsAdGroupName',
        sessionGoogleAdsAdNetworkType: 'sessionGoogleAdsAdNetworkType',
        sessionGoogleAdsCreativeId: 'sessionGoogleAdsCreativeId',
        sessionGoogleAdsKeyword: 'sessionGoogleAdsKeyword',
        sessionGoogleAdsQuery: 'sessionGoogleAdsQuery',
        sessionMedium: 'sessionMedium',
        sessionSource: 'sessionSource',
        sessionSourcePlatform: 'sessionSourcePlatform',
        signedInWithUserId: 'signedInWithUserId',
        source: 'source',
        sourcePlatform: 'sourcePlatform',
        streamId: 'streamId',
        streamName: 'streamName',
        testDataFilterName: 'testDataFilterName',
        transactionId: 'transactionId',
        unifiedPagePathScreen: 'unifiedPagePathScreen',
        unifiedPageScreen: 'unifiedPageScreen',
        unifiedScreenClass: 'unifiedScreenClass',
        unifiedScreenName: 'unifiedScreenName',
        userAgeBracket: 'userAgeBracket',
        userGender: 'userGender',
        videoProvider: 'videoProvider',
        videoTitle: 'videoTitle',
        videoUrl: 'videoUrl',
        visible: 'visible',
        week: 'week',
        year: 'year',
        customEvent: {
            link: 'customEvent:link'
        }
    },
    allDimensionFilters: {
        matchType: {
            numeric: {
                equal: 'EQUAL',
                lessThan: 'LESS_THAN',
                lessThanOrEqual: 'LESS_THAN_OR_EQUAL',
                greaterThan: 'GREATER_THAN',
                greaterThanOrEqual: 'GREATER_THAN_OR_EQUAL'
            },
            string: {
                exact: 'EXACT',
                beginsWith: 'BEGINS_WITH',
                endsWith: 'ENDS_WITH',
                contains: 'CONTAINS',
                fullRegExp: 'FULL_REGEXP',
                partialRegExp: 'PARTIAL_REGEXP'
            }
        }
    },
    allMetrics: {
        active1DayUsers: 'active1DayUsers',
        active28DayUsers:'active28DayUsers',
        active7DayUsers: 'active7DayUsers',
        activeUsers:'activeUsers',
        adUnitExposure: 'adUnitExposure',
        addToCarts: 'addToCarts',
        averagePurchaseRevenue: 'averagePurchaseRevenue',
        averagePurchaseRevenuePerPayingUser: 'averagePurchaseRevenuePerPayingUser',
        averagePurchaseRevenuePerUser: 'averagePurchaseRevenuePerUser',
        averageRevenuePerUser: 'averageRevenuePerUser',
        averageSessionDuration: 'averageSessionDuration',
        cartToViewRate: 'cartToViewRate',
        checkouts: 'checkouts',
        cohortActiveUsers: 'cohortActiveUsers',
        cohortTotalUsers: 'cohortTotalUsers',
        conversions: 'conversions',
        crashAffectedUsers: 'crashAffectedUsers',
        crashFreeUsersRate: 'crashFreeUsersRate',
        dauPerMau: 'dauPerMau',
        dauPerWau: 'dauPerWau',
        ecommercePurchases: 'ecommercePurchases',
        engagedSessions: 'engagedSessions',
        engagementRate: 'engagementRate',
        eventCount: 'eventCount',
        eventCountPerUser: 'eventCountPerUser',
        eventValue: 'eventValue',
        eventsPerSession: 'eventsPerSession',
        firstTimePurchaserConversionRate: 'firstTimePurchaserConversionRate',
        firstTimePurchasers: 'firstTimePurchasers',
        firstTimePurchasersPerNewUser: 'firstTimePurchasersPerNewUser',
        itemListClickThroughRate: 'itemListClickThroughRate',
        itemListClicks: 'itemListClicks',
        itemListViews: 'itemListViews',
        itemPromotionClickThroughRate: 'itemPromotionClickThroughRate',
        itemPromotionClicks: 'itemPromotionClicks',
        itemPromotionViews: 'itemPromotionViews',
        itemPurchaseQuantity: 'itemPurchaseQuantity',
        itemRevenue: 'itemRevenue',
        itemViews: 'itemViews',
        newUsers: 'newUsers',
        publisherAdClicks: 'publisherAdClicks',
        publisherAdImpressions: 'publisherAdImpressions',
        purchaseRevenue: 'purchaseRevenue',
        purchaseToViewRate: 'purchaseToViewRate',
        purchaserConversionRate: 'purchaserConversionRate',
        screenPageViews: 'screenPageViews',
        screenPageViewsPerSession: 'screenPageViewsPerSession',
        sessions: 'sessions',
        sessionsPerUser: 'sessionsPerUser',
        totalAdRevenue: 'totalAdRevenue',
        totalPurchasers: 'totalPurchasers',
        totalRevenue: 'totalRevenue',
        totalUsers: 'totalUsers',
        transactions: 'transactions',
        transactionsPerPurchaser: 'transactionsPerPurchaser',
        userEngagementDuration: 'userEngagementDuration',
        wauPerMau: 'wauPerMau'
    }
}
