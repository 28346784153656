import {paymentOperators, successStatuses, dateFormat, hoursFormat} from '@/utils/macros/variables'
import moment from "moment";

export const counterHelper = (arr) => arr.reduce((old, next) => old + next, 0)
export const sortHelperNumbers = (first, second) => first === second ? 0 : (first > second) ? -1 : 1
export const sortHelperDates = (first, second) => {
    first = calculateTimestamp(first)
    second = calculateTimestamp(second)
    return first === second ? 0 : (first > second) ? 1 : -1
}
export const amountHelper = (amount) => {
    const condition = typeof amount === 'string'
    return condition ? Number.parseFloat(amount) : amount / 100
}

export const calculateTimestamp = (createTimestamp) => {
    const timestampType = typeof createTimestamp === 'object' // TIMESTAMP CAN HAVE TWO TYPES OBJECT AND DATE

    return timestampType ? createTimestamp
        .toDate()
        .valueOf() : createTimestamp
}
export const formatDate = (date) => moment(
    new Date(date)
)

export const isBlueMedia = (paymentOperator) => paymentOperator === paymentOperators.bluemedia
export const isBlueMediaActiveUser = (bmConfig) => bmConfig && (bmConfig.otherMethodsEnabled || bmConfig.cardsEnabled)
export const isTpay = (paymentOperator) => paymentOperator === paymentOperators.tpay
export const isTpayActiveUser = (paymentOperator) => paymentOperator === paymentOperators.tpay
export const isStripe = (paymentOperator) => (paymentOperator === paymentOperators.stripe)
export const isStripeActiveUser = ({
                                       preferStripeKeys,
                                       stripePublicKey,
                                       stripeUserId,
                                       stripeChargesEnabled
                                   }) => ((preferStripeKeys && stripePublicKey) || (stripeUserId && stripeChargesEnabled))
export const countSuccessAndActiveUsers = ({overviewData, user}) => {
    if (successStatuses.includes(user.bluemediaVerification)) overviewData.bmSuccessUsers += 1 // IF BLUE MEDIA VERIFICATION IS SUCCESS COUNT
    if (isBlueMedia(user.paymentOperator) && isBlueMediaActiveUser(user.bluemediaPaymentConfig)) overviewData.bmActiveUsers += 1 // IF BLUE MEDIA USERS CAN COLLECTING MONEY
    if (isStripe(user.paymentOperator) && isStripeActiveUser(user)) overviewData.stripeActiveUsers += 1 // IF STRIPE USERS CAN COLLECTING MONEY
    if (isTpay(user.paymentOperator) && isTpayActiveUser(user)) overviewData.stripeActiveUsers += 1 // IF TPAY USERS CAN COLLECTING MONEY
    return overviewData
}

export const filterUsersDataAndSortByCollectedMoney = (usersData, testCollections) => usersData
    .filter((element) => element && element.uid && element.link && testCollections.docs.findIndex(({id}) => id === element.link) === -1) // FILTER USERS DATA IN CASE UNDEFINED USER
    .sort((a, b) => sortHelperNumbers(a.totalAmount, b.totalAmount)) // SORT DESCENDING BY COLLECTED MONEY - SORTING BY ALL CURRENCIES SUM TOTAL

export const calculatePlnBalances = (collectionsBalances, testCollections) => Object.keys(collectionsBalances).map(link => {
    const isRealCollection = testCollections.docs.findIndex(({id}) => id === link) === -1 // CHECKING IF TEST COLLECTIONS EXISTS - IT IS IMPORTANT IN PRODUCTION DATA
    return isRealCollection ? collectionsBalances[link].pln.amount : 0 // IF COLLECTION IS REAL USE COLLECTED AMOUNT IF NOT DON'T COUNT - FILL 0
}).reduce((acc, element) => acc + element, 0).toLocaleString() + ' PLN' // CALCULATING ALL COLLECTED MONEY BY COLLECTION

export const calculateDailyTransactions = ({
                                               dailyTransactions,
                                               createTimestamp,
                                               currency,
                                               amount,
                                               status,
                                           }, callback = () => {
}) => {
    const date = formatDate(createTimestamp)
    const dateFromEpoch = date.format(dateFormat)
    const timeFromEpoch = date.format(hoursFormat)
    const currencyFormatted = currency.toUpperCase()
    const isSuccess = successStatuses.includes(status)

    if (dailyTransactions[dateFromEpoch]) { // CALCULATE REVENUE, TRANSACTIONS COUNT PER CURRENT DATE - DAILY REPORTS
        const transactionsRef = dailyTransactions[dateFromEpoch]
        const current = transactionsRef.revenue[currencyFormatted]
        const value = isSuccess ? amountHelper(amount) : 0

        transactionsRef.revenue[currencyFormatted] = current ? current + value : value // REVENUE INCREMENT ON SPECIFIC

        if (isSuccess) {
            transactionsRef.success += 1 // IF PAYMENT WAS SUCCESS INCREMENT SUCCESS
            let reference = dailyTransactions[dateFromEpoch].hoursInTransactions // NEED TO REF HERE BECAUSE OVER THIS DECLARATION COULD RETURN ERROR

            if (reference[timeFromEpoch]) reference[timeFromEpoch] += 1 // INCREMENT SPECIFIC HOUR
            else reference[timeFromEpoch] = 1 // INIT SPECIFIC HOUR

            callback({
                day: dateFromEpoch,
                hour: timeFromEpoch
            })
        } else {
            transactionsRef.other += 1 // IF OTHER STATUSES INCREMENT THEM
        }
    } else { // IF CURRENT DATE NOT EXIST IN OBJECT - CREATE
        dailyTransactions[dateFromEpoch] = {
            hoursInTransactions: {},
            revenue: {},
            success: ~~isSuccess,
            other: ~~!isSuccess,
        }

        dailyTransactions[dateFromEpoch].revenue[currencyFormatted] = isSuccess ? amountHelper(amount) : 0 // INIT REVENUE VALUE FOR CURRENCY

        if (isSuccess) {
            dailyTransactions[dateFromEpoch].hoursInTransactions[timeFromEpoch] = 1// INIT HOURS DATA
        }

        callback({
            day: dateFromEpoch,
            hour: timeFromEpoch
        })
    }

    return dailyTransactions
}

export const sortTagsByCollectedMoney = (tags) => tags.sort((a, b) => {
    const first = a.amount ? counterHelper(Object.values(a.amount)) : 0
    const second = b.amount ? counterHelper(Object.values(b.amount)) : 0
    return sortHelperNumbers(first, second)
})

export const mapBalancesToCurrencyAmountFormat = (collectionsBalances, link) => Object.keys(collectionsBalances[link]).map(currency => {
    return {
        currency,
        amount: collectionsBalances[link][currency].amount
    }
})
export const resolvePaymentsCollection = async (paymentsDocs) => await Promise.all(paymentsDocs.docs.map(async item => {
    const response = await item.data() // MAPPING ALL USER PAYMENTS DATA
    return response
}))

export const getLastTenPayments = (sortedTransactions, link) => {
    const reversedTransactions = sortedTransactions
        .filter(({status}) => successStatuses
            .includes(status))
        .reverse()

    return reversedTransactions
        .map(({createTimestamp, currency, amount}, index) => index <= 10 ? {
            link,
            createTimestamp,
            date: moment(new Date(calculateTimestamp(createTimestamp)))
                .format(dateFormat + ' HH:mm'),
            currency,
            amount: amountHelper(amount)
        } : false)
        .filter(element => !!element).slice(0, 10)
}

export const prepareLastTenTransactions = (lastTransactionsAllCollections) => lastTransactionsAllCollections.sort((a, b) => sortHelperDates(a.createTimestamp, b.createTimestamp)).slice(-10).reverse()

export const prepareAvgPaymentTimeData = (docs) => {
    return docs.reduce((acc, doc) => {
        const data = Object.entries(doc.data()).map((item) => {
            item[0] = `${item[0]}/${doc.id}`
            return item
        })
        acc.push(...data)
        return acc
    }, []).sort(([a], [b]) => {
        // Revert date DD/MM/YYYY -> YYYYMMDD
        const _a = a.split('/').reverse().join()
        const _b = b.split('/').reverse().join()
        return _a < _b ? -1 : (_a > _b) ? 1 : 0
    })
}
