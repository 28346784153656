<template>
  <div id="loader" class="full-size flex-centered">
    <loader-animation />

    <div v-if="getLoaderMessage" class="mt-4">
      {{ getLoaderMessage }} <span v-if="timer">[{{timer}} s]</span>
    </div>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import LoaderAnimation from "@/components/global/LoaderAnimation";

export default {
  name: "Loader",
  components: {LoaderAnimation},
  data: () => ({
    timer: undefined
  }),
  computed: {
    ...mapGetters('components', ['getLoaderMessage'])
  },
  mounted() {
    this.timer = 0
  },
  props: {
    size: {type: Number, default: 100}
  },
  watch: {
    timer() {
      setTimeout(() => {
        this.timer += 1
      }, 1000)
    }
  }
}
</script>
<style scoped>
#loader {
  flex-direction: column;
}
</style>


