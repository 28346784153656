import {version} from '../../package.json'

export default {
    install(Vue) {
        Vue.config.productionTip = false

        const removeReference = (data) => JSON.parse(JSON.stringify(data))

        const numberSortAsc = (first, second) => first === second ? 0 : first > second ? 1 : -1
        const numberSortDesc = (first, second) => first === second ? 0 : first > second ? -1 : 1

        const sortHelper = (a, b, isDesc) => isDesc ? numberSortAsc(a,b) : numberSortDesc(a,b)

        Vue.prototype.$appVersion = version
        Vue.prototype.$mainColor = 'primary'
        Vue.prototype.$isDevMode = process.env.NODE_ENV === 'development'

        Vue.prototype.$sortHelper = sortHelper
        Vue.prototype.$numberSortAsc = numberSortAsc
        Vue.prototype.$numberSortDesc = numberSortDesc
        Vue.prototype.$removeReference = removeReference
    }
}
