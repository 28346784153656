import Vue from 'vue'
import Vuex from 'vuex'

import authentication from './authentication'
import components from './components'
import statistics from './statistics'
import users from './users'

Vue.use(Vuex)

export default new Vuex.Store({
    strict: process.env.NODE_ENV !== 'production',
    modules: {
        authentication,
        components,
        statistics,
        users
    }
})
