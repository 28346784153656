import Vue from 'vue'
import App from './App.vue'
import vuetify from '@/plugins/vuetify'
import vueGoogleCharts from '@/plugins/vueGoogleCharts'
import firebase from '@/plugins/firebase-init'
import GlobalHelper from '@/plugins/global-helper'
import router from './router'
import store from './store'
import firebaseFunctions from '@/plugins/firebase-functions'
import VueMeta from 'vue-meta'

import LayoutDashboard from '@/layouts/Dashboard'
import LayoutLoaderView from '@/layouts/LoaderView'

Vue.use(firebase)
Vue.use(GlobalHelper)
Vue.use(firebaseFunctions)

Vue.component('LayoutDashboard', LayoutDashboard)
Vue.component('LayoutLoaderView', LayoutLoaderView)

Vue.use(VueMeta,{
    keyName: 'head'
})

new Vue({
    vuetify,
    vueGoogleCharts,
    router,
    store,
    render: h => h(App)
}).$mount('#app')
