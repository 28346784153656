/* eslint-disable */
import {
    successStatuses,
    currenciesObject,
    paymentOperatorConnections,
    blueMediaStatuses
} from '@/utils/macros/variables'

import * as helpers from '@/services/helpers'

import fetchPaymentsSummary from '@/services/statistics/paymentsVuyap'
import {prepareAvgPaymentTimeData} from "@/services/helpers";
import moment from "moment/moment";
import firebase from "firebase/app";

const blueMediaExcludeStatuses = [blueMediaStatuses.failure, blueMediaStatuses.pending]

export default {
    async globalAmountsTypes({commit, state}) {
        const globalStatsPayments = await state.firebaseReference('stats').doc('payments').get()

        commit('addToGlobalStatistics', {
            key: 'amountsTypes',
            value: globalStatsPayments.data()?.amountChosenTypes
        })
    },
    async collectionAmountsTypes({commit, state}, {id, link}) {
        const userStatsPayments = await state.firebaseReference('users').doc(id).collection('stats').doc('payments').get()
        commit('addToCollectionsStatistics', {
            key: link,
            sector: 'amountsTypes',
            value: userStatsPayments.data()?.amountChosenTypes
        })
    },
    async fetchAllStickers({commit, state}) {
        const globalStatsStickers = await state.firebaseReference('stats').doc('stickers').get()

        commit('setAllStickers', {
                allTags: globalStatsStickers.data().amount,
            }
        )
    },
    async fetchBlueMediaDonationProcessStats({commit, state}) {
        const globalStatsPayments = await state.firebaseReference('stats').doc('payments').get()
        const data = globalStatsPayments.data()?.blueMediaDonationProcess
        if (data) commit('setGlobalBlueMediaDonationProcess', Object.entries(data).filter(([key]) => !blueMediaExcludeStatuses.includes(key)))
    },
    async fetchAllUsers({commit, state}) {
        if (!state.allUsersData) {
            const globalUsers = await state.firebaseReference('users').get()
            const response = await Promise.all(globalUsers.docs.map(async (user) => ({
                ...user.data(),
                uid: user.id
            })))

            commit('setGlobalUsers', response)
        }
    },
    async fetchAllPayments({state, commit}, range) {
        if (state.allUsersData) {
            const searchDate = moment().subtract(range, 'days')
            const searchTimestamp = firebase.firestore.Timestamp.fromDate(new Date(searchDate))

            const payments = await Promise.all(state.allUsersData.map(async user => {
                return (await state.firebaseReference('users').doc(user.uid).collection('payments')
                    .where('createTimestamp', '>=', searchTimestamp)
                    .where('status', 'in', successStatuses).get()).docs.map(payment => payment.data())
            }))
            commit('setAllYearPayments', payments.flat())
        }
    },
    fetchPayments({state, commit}, {dateTime,cb = () => {}}) {
        if (state.allPayments && !state.allPaymentsPerDay?.[dateTime]) {
            const data = fetchPaymentsSummary({
                dateTime,
                payments: state.allPayments
            })

            commit('addPaymentsPerDay', {data, dateTime})

            cb()
        }
    },
    async fetchWaitingOrders({commit, state}) {
        const {docs} = await state.firebaseReference('orders_history').get()
        commit('setWaitingOrders', docs.length)
    },
    async fetchAvgPaymentTime({commit, state}) {
        const {docs} = await state.firebaseReference('stats/payments/avgTime').get()

        commit('setGlobalAvgPaymentTime', prepareAvgPaymentTimeData(docs))
    },
    async fetchCollectionAvgPaymentTime({state}, userId) {
        const {docs} = await state.firebaseReference(`users/${userId}/stats/payments/avgTime`).get()

        // TODO: Return is a temporary solution, in the future we should use a "commit" syntax
        return prepareAvgPaymentTimeData(docs)
    },
    async fetchAllData({commit, getters: {getAllUsers, getWaitingOrders}, state, dispatch}) {
        let overviewData = paymentOperatorConnections, lastTransactionsAllCollections = []

        const appUsers = await this._vm.$functions.fetch(this._vm.$functions.endpoints.amountOfUsers)
        const collectionsBalances = {}
        const [
            testCollections
        ] = await Promise.all([
            this._vm.$isDevMode ? {docs: []} : state.firebaseReference('testCollectionsByLink').get()
        ])

        const usersData = helpers.filterUsersDataAndSortByCollectedMoney(
            getAllUsers.map(user => {
                let totalAmount = 0

                collectionsBalances[user.link] = {}

                overviewData = helpers.countSuccessAndActiveUsers({ // TODO: I CRATED EARLIER SOLUTION WITH CALL AND PASSING CONCEPT, BUT THERE WAS SMTH BAD SO FOR NOW I STAY WITH THIS SOLUTION
                    overviewData,
                    user
                })

                Object.entries(user.balance).forEach(([key, value]) => {
                    collectionsBalances[user.link][key] = value // MAPPING BALANCES COLLECTION FOR EACH COLLECTION TO OBJECT
                    totalAmount += value.amount // SUM ALL CURRENCIES AS MEASUREMENT SUM PLN + SUM EUR ETC ... - IT IS USED FOR SORTING BELOW
                })

                return {
                    ...user,
                    totalAmount // ADDITIONAL KEY IN USER DATA FOR SORTING BELOW BY COLLECTED ALL MONEY
                }
            }), testCollections)

        const plnBalances = helpers.calculatePlnBalances(collectionsBalances, testCollections)

        commit('setAppOverview',
            {
                allUsersData: appUsers.amount,
                shopOrders: getWaitingOrders,
                ...overviewData,
                collectedByUsers: plnBalances
            }
        )

        // END FETCHING SECTION FOR APP OVERVIEW

        await Promise.all(usersData.map(async (
                {
                    uid: id,
                    displayName,
                    link,
                    paymentOperator,
                    customLink,
                    stickers
                }
                , index) => {
                let noTagsProfit = []

                const userReference = state.firebaseReference('users').doc(id)

                const [userPaymentStatsReference, paymentsDocs] = await Promise.all([
                    userReference.collection('stats').doc('payments').get(), // TODO: MOVE THIS TO SEPARATED ACTION
                    userReference.collection('payments').get()
                ])

                const userPaymentStats = await userPaymentStatsReference.data() || {} // Don't destructing for better read

                const balances = helpers.mapBalancesToCurrencyAmountFormat(collectionsBalances, link)
                const transactions = await helpers.resolvePaymentsCollection(paymentsDocs)

                const tags = Object.entries(stickers || {}).map(([key, {amount}]) => {
                    const tagsTransactions = transactions.filter(({boxId}) => {
                        return key == boxId // INTENTED - BOX IF COULD BE NUMBER AND STRING
                    })

                    const successOnly = tagsTransactions.filter(({status}) => successStatuses.includes(status)).length

                    return {
                        id: key,
                        amount,
                        transactions: {
                            other: tagsTransactions.length - successOnly,
                            success: successOnly
                        }
                    }
                })

                balances.forEach(element => {
                    noTagsProfit.push({...element})
                })

                tags.forEach(({amount}) => {
                    if (amount) Object.keys(amount).forEach(item => {
                        const index = noTagsProfit.findIndex(e => e.currency.toLowerCase() === item.toLowerCase())

                        if (index !== -1) noTagsProfit[index].amount -= amount[item]
                    })
                })

                let dailyTransactions = {}

                const sortedTransactions = transactions
                    .sort((a, b) => helpers.sortHelperDates(a.createTimestamp, b.createTimestamp)) // SORTING ALL USER PAYMENTS BY TIME

                sortedTransactions
                    .forEach(({createTimestamp, status, amount, currency}) => {
                        createTimestamp = helpers.calculateTimestamp(createTimestamp)

                        dailyTransactions = helpers.calculateDailyTransactions({ // TODO: I CREATED EARLIER SOLUTION WITH CALL AND PASSING CONCEPT, BUT THERE WAS SMTH BAD SO FOR NOW I STAY WITH THIS SOLUTION
                            dailyTransactions,
                            createTimestamp,
                            currency,
                            amount,
                            status
                        }, (payload) => {
                            commit('addToAverageDailyHoursTransactions', payload)
                        })
                    })

                lastTransactionsAllCollections = [...lastTransactionsAllCollections, ...helpers.getLastTenPayments(sortedTransactions, link)] // COLLECTING LAST 10 TRANSACTIONS ON VUYAP

                const avgPaymentTime = await dispatch('fetchCollectionAvgPaymentTime', id)

                const {
                    allBySticker = 0,
                    allByGlobal = 0,
                    successOnlyBySticker = 0,
                    successOnlyByGlobal = 0
                } = userPaymentStats.paymentsCount || {}

                const otherByGlobal = allByGlobal - successOnlyByGlobal,
                    otherByStickers = allBySticker - successOnlyBySticker

                const blueMediaDonationProcess = Object.entries(userPaymentStats.blueMediaDonationProcess || {})
                    .filter(([key]) => !blueMediaExcludeStatuses.includes(key))

                const response = {
                    id,
                    displayName,
                    link,
                    customLink,
                    paymentOperator,
                    balances,
                    dailyTransactions,
                    avgPaymentTime,
                    blueMediaDonationProcess,
                    amountsInTransactions: {
                        ...currenciesObject,
                        PLN: userPaymentStats.amounts
                    },
                    tags: helpers.sortTagsByCollectedMoney(tags),
                    transactionsAmount: {
                        byStickers: {
                            other: otherByStickers > 0 ? otherByStickers : 0,
                            success: successOnlyBySticker,
                        },
                        byGlobalCollection: {
                            other: otherByGlobal > 0 ? otherByGlobal : 0,
                            success: successOnlyByGlobal,
                        },
                        allTransactions: {
                            other: (allBySticker + allByGlobal) - (successOnlyBySticker + successOnlyByGlobal),
                            success: successOnlyBySticker + successOnlyByGlobal
                        }
                    },
                    noTagsProfit
                }

                commit('addToAppStatistics', response)
                usersData.length - 1 === index && commit('setAppStatisticsFetching', false)
                return response
            })
        )

        commit('addLastTransactions', helpers.prepareLastTenTransactions(lastTransactionsAllCollections))
    }
}
