export default {
    setGlobalOS: (state, payload) => {
        state.globalOS = payload
    },
    setGlobalPhonesModels: (state, payload) => {
        state.globalPhonesModels = payload
    },
    setDonationPageOS: (state, payload) => {
        state.globalOS = payload
    }
}
