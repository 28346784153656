import ga from "@/services/google-analytics/index";

export const fetchAllGlobal = async function () {

    const mutationPath = 'statistics/googleAnalytics'

    const [osData, phonesModels] = await Promise.all([
        ga.getAnalyticsData.call(
            this,
            this.getGlobalOSChartSettings
        ),
        ga.getAnalyticsData.call(
            this,
            this.getGlobalPhonesModelsChartSettings
        )
    ])

    this.$store.commit(`${mutationPath}/setGlobalOS`, osData)
    this.$store.commit(`${mutationPath}/setGlobalPhonesModels`, phonesModels)

}
