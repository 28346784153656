export default {
    setAppOverview: (state, payload) => {
        state.appOverview = payload
    },
    setAllStickers: (state, payload) => {
      state.allStickers = payload
    },
    addLastTransactions: (state, payload) => {
        state.lastTransactions = payload
    },
    setAppStatisticsFetching: (state, payload) => {
        state.appStatisticsFetching = payload
    },
    setCurrentCollection: (state, payload) => {
      state.currentCollection = payload
    },
    setGlobalBlueMediaDonationProcess: (state, payload) => {
        state.globalBlueMediaDonationProcess = payload
    },
    setGlobalUsers: (state, payload) => {
      state.allUsersData = payload
    },
    setAllYearPayments: (state, payload) => {
        state.allPayments = payload
    },
    addPaymentsPerDay: (state, {dateTime, data}) => {
      state.allPaymentsPerDay[dateTime] = data
    },
    setWaitingOrders: (state, payload) => {
        state.waitingOrders = payload
    },
    setGlobalAvgPaymentTime: (state, payload) => state.globalAvgPaymentTime = payload,
    addToAppStatistics: (state, payload) => {
        state.appStatistics.push(payload)
    },
    addToAverageDailyHoursTransactions: (state, {day, hour}) => {
        const reference = `averageDailyHoursTransactions`

        if (state[reference][day]) {
            if (state[reference][day][hour]) {
                state[reference][day][hour] += 1
            } else {
                state[reference][day][hour] = 1
            }
        } else {
            state[reference][day] = {
                [hour]: 1
            }
        }
        state[reference] = {...state[reference]} // INTENTIONAL, IT IS NEED TO WATCH CHANGES ON THIS
    },
    addToGlobalStatistics: (state, {key, value}) => {
        state.globalStatistics[key] = value
        state.globalStatistics = {...state.globalStatistics}
    },
    addToCollectionsStatistics: (state, {key, sector, value}) => {
        if(!state.collectionsStatistics[key]) {
            state.collectionsStatistics[key] = {}
        }
        state.collectionsStatistics[key][sector] = value
        state.collectionsStatistics = {...state.collectionsStatistics}
    }
}
