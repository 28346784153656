import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/Login.vue'),
        meta: {
            checkAuthorization: true
        }
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        redirect: '/dashboard/overview'
    },
    {
        path: '/dashboard/admins',
        name: 'admins',
        component: () => import('@/views/Dashboard/Admins'),
        meta: {
            checkAuthorization: true
        }
    },
    {
        path: '/dashboard/overview',
        name: 'overview',
        component: () => import('@/views/Dashboard/Overview.vue'),
        meta: {
            checkAuthorization: true
        }
    },
    {
        path: '/dashboard/statistics',
        name: 'statistics',
        component: () => import('@/views/Dashboard/Statistics.vue'),
        meta: {
            checkAuthorization: true
        }
    },
    {
        path: '/dashboard/statistics/collection-evidence/:link',
        name: 'collection-evidence',
        component: () => import('@/views/Dashboard/CollectionEvidence.vue'),
        meta: {
            checkAuthorization: true
        }
    },
    {
        path: '/check-login',
        name: 'check-login',
        component: () => import('@/views/CheckLogin.vue'),
    },
    {
        path: '/dashboard/users-activity',
        name: 'Users activity',
        component: () => import('@/views/Dashboard/UserActivity.vue'),
        meta: {
            checkAuthorization: true
        }
    },
    {
        path: '/dashboard/payments-stats',
        name: 'Payments stats',
        component: () => import('@/views/Dashboard/PaymentsStats.vue'),
        meta: {
            checkAuthorization: true
        }
    },
    {
        path: '/',
        redirect: '/login'
    }
]
const CHECK_AUTH = 'check-login'
const LOGIN_PATHS = ['/', '/login']

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

const routesActions = {
    '/login': (to, {fullPath}, next) => {
        if (LOGIN_PATHS.includes(fullPath)) {
            next('/dashboard')
        } else {
            next()
        }
    },
    default(to, from, next) {
        const {authenticated} = store.getters["authentication/getCurrentUser"]

        if (to?.meta?.checkAuthorization && !from.fullPath.includes(CHECK_AUTH) && !authenticated) {
            router.push(`/${CHECK_AUTH}?to=${to.path}`)
        } else {
            next()
        }
    }
}

router.afterEach((to) => {
    if (to.fullPath.includes('collection-evidence')) {
        window.scroll(0, 0)
    }
})

router.beforeEach(async (to, from, next) => {
    if (routesActions[to.fullPath]) {
        routesActions[to.fullPath](to, from, next)
    } else {
        await routesActions.default(to, from, next)
    }
})

export default router
