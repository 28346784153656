import moment from "moment";

export const currencies = ['pln', 'eur', 'usd', 'gbp']

export const oneYearPeriod = 365

export const currenciesObject = {
    PLN: {},
    EUR: {},
    USD: {},
    GBP: {}
}

export const blueMediaStatuses = {
    failure: 'failure',
    pending: 'pending',
    none: 'none',
    success: 'success'
}

export const paymentOperatorConnections = {
    bmSuccessUsers: 0,
    bmActiveUsers: 0,
    stripeActiveUsers: 0,
}

export const sectionsTitles = {
    paymentsProcess: 'Payments process'
}

export const paymentOperatorsTitles = {
    blueMedia: 'Blue Media',
    stripe: 'Stripe',
    tpay: 'Tpay'
}
export const blueMediaPaymentsProcessChartHeaders = ['Started payment', 'End payment with success']

export const successStatuses = ['succeeded', 'SUCCESS']

export const dateFormat = 'YYYY-MM-DD'

export const dateTimeFormat = `${dateFormat} - HH:mm`

export const hoursFormat = 'HH'

export const paymentOperators = {
    stripe: 'stripe',
    bluemedia: 'bluemedia',
    tpay: 'tpay'
}

export const dateRangeType = {
    month: 'month',
    date: 'date'
}

export const prefixForTransactions = 'of transactions'

export const usersPhonesModelsHeaders = [
    'Phone model',
    'Amount'
]

export const avgPaymentTimeHeaders = [
    'Date',
    'Stripe',
    'Bluemedia',
    'Tpay'
]

export const transactionsChartHeaders = [
    'Link',
    'Success [Global collection]',
    'Success [Stickers]',
    'Other [Global collection]',
    'Other [Stickers]',
]
export const amountsTypesImplementationDate = 'Measured since - 26.01.2022, 13:30' // THE DATE OF IMPLEMENTATION IN MAIN APP
export const transactionsHoursChartHeaders = ['Hour', 'All collections transactions amount']

export const revenueChartHeaders = [
    'Link',
    ...currencies.map(element => element.toUpperCase())
]

export const osChartHeaders = [
    'OS',
    'Amount'
]

export const amountsTypesHeaders = [
    'Donation button',
    'Donation input',
]

export const collectionsTypes = {
    all: '[Stickers + global collection]',
    global: '[Global collection]',
    stickers: '[Stickers only]'
}


export const googleChartTypes = {
    columnChart: 'ColumnChart',
    lineChart: 'LineChart',
    pieChart: 'PieChart',
    barChart: 'BarChart',
}

export const chartTitles = {
    dailyRevenueChart: 'Date frame statistics'
}

export const hoursObject = (() => {
    const times = {}
    for (let i = 0; i < 24; i += 1) {
        times[`${i < 10 ? '0' : ''}${i}`] = 0
    }
    return times
})()

export const amountTypes = {
    typed: 'Custom amount input',
    clicked: 'Button with amount'
}

export const tooltips = {
    globalRevenueChart: 'This chart represents total all time revenue collected by each collection. <br> <div class="flex-centered">X - collections, Y - revenue</div>',
    globalAmountsTypesChart: 'This chart represents global lifetime measurement of using donation buttons vs donation input.',
    globalTransactionsCount: 'This chart represents total all time count of transactions for each collection. <br> <div class="flex-centered">X - collections, Y - count of transactions</div>',
    globalTransactionsCountPerHours: 'This chart represents global success payments done in specific hours in specific date frame. <br> <div class="flex-centered">X - hour, Y - count of transactions</div>',
    collectionRevenueChart: 'This chart represents revenue for this collection in selected date frame. <br> <div class="flex-centered">X - date, Y - Revenue amount</div>',
    collectionTransactionsCountChart: 'This chart represents total count of transactions per days for this collection in selected date frame. <br> <div class="flex-centered">X - date, Y - count of transactions</div>',
    collectionTransactionsHoursCountChart: 'This chart represents sum of total count of transactions splited per hours for this collection in selected date frame. <br> <div class="flex-centered">X - date, Y - count of transactions</div>',
    globalOSChart: 'This chart represents operating systems used by users',
    phonesModelsChart: 'This chart represents phones models of users.',
    paymentsProcess: 'This chart represents several stages of payments process for each operator with redirection payments.',
    avgPaymentTime: 'This chart represents average payment time (in seconds).'
}

export const allDates = (() => { // HERE IS READY SOLUTION FOR FILL DATES IN CURRENT DATE FRAME BUT IT SHOULD BE DISCUSSED
    const dates = {};
    let dateToIncrement = moment('2020-06-01').format(dateFormat); // START VALUE IS THE BEGINNING OF VUYAP
    const endDate = moment().format(dateFormat)

    while (dateToIncrement <= endDate) {
        dates[dateToIncrement] = {
                hoursInTransactions: {},
                other: 0,
                revenue: {
                    PLN: 0
                },
                success: 0
        }
        dateToIncrement = moment(dateToIncrement).add(1, 'days').format(dateFormat)
    }

    // return dates
    return {}
})()
