<template>
  <div id="app">
    <v-app>
      <router-view />
    </v-app>
  </div>
</template>

<script>
import {mapGetters, mapActions, mapMutations} from "vuex";
import {fetchAllGlobal} from '@/services/google-analytics/fetch-global'
import globalOsChart from "@/mixins/google-analytics/global-os-chart";
import {oneYearPeriod} from '@/utils/macros/variables'

export default {
  name: 'App',
  mixins: [
      globalOsChart
  ],
  head() {
    return {
      title: `${this.$isDevMode ? '[Dev mode] ' : ''}Admin panel`
    }
  },
  computed: {
    ...mapGetters('authentication', ['getCurrentUser']),
    ...mapGetters('statistics', ['getAppStatistics', 'getCurrentCollection', 'getAppStatisticsFetching']),
  },
  methods: {
    ...mapMutations('statistics', ['setAppOverview', 'setCurrentCollection']),
    ...mapActions('statistics', [
      'fetchAllData',
      'fetchAllStickers',
      'fetchBlueMediaDonationProcessStats',
      'fetchAllUsers',
      'fetchWaitingOrders',
      'fetchAvgPaymentTime',
        'fetchAllPayments'
    ]),
    detectCurrentCollection() {
      const {link: queryLink} = this.$route.params
      const index = this.getAppStatistics.findIndex(({link}) => link === queryLink)
      if (index > -1) this.setCurrentCollection(this.getAppStatistics[index])
    },
  },
  watch: {
    '$route.params'({link}){ // SETTING CURRENT COLLECTION BY ACTUAL LINK IN PARAMS QUERY
      if(link) {
        this.detectCurrentCollection()
      } else {
        this.setCurrentCollection(undefined)
      }
    },
    getAppStatistics() {
      this.getCurrentCollection || this.detectCurrentCollection()
    },
    getCurrentUser({uid: newUid, authenticated}, {uid: oldUid}) {
      authenticated && (newUid !== oldUid) && (async () => {

        await Promise.all([
          this.fetchAvgPaymentTime,
          this.fetchWaitingOrders,
          async () => {
            await this.fetchAllUsers()
            this.fetchAllPayments(oneYearPeriod)
          },
          this.fetchAllStickers,
          this.fetchBlueMediaDonationProcessStats,
          async () => await fetchAllGlobal.call(this)
        ].map(async task => await task()))

        this.fetchAllData()
      })()
    }
  }
}

</script>

<style lang="scss">
html {
  overflow-y: auto !important;
}

#app {
  height: 100vh;
  width: 100vw;
  font-family: Konnect-Regular, -apple-system, BlinkMacSystemFont, Segoe UI,
  Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  color: #2c3e50;

  a {
    width: 100%;
    text-decoration: none;
  }

  .full-size {
    width: 100%;
    height: 100%;

    &-width {
      width: 100%;
    }

    &-height {
      height: 100%;
    }
  }

  .flex-centered {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .svg-funnel-js { // External funnel chart

    .label {
      &__value {
        color: rgba(0, 0, 0, 0.6);
      }

      &__title {
        color: rgba(0, 0, 0, 0.6);
        font-size: 14px;
      }

      &__percentage{
        color: rgba(0, 0, 0, 0.6);
      }
    }

    &:not(.svg-funnel-js--vertical) .svg-funnel-js__label:not(:first-child) {
      border-left: 1px solid rgba(0, 0, 0, 0.6);
    }
  }

}


</style>
