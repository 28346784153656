var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout-loader-view',[_c('v-sheet',[_c('v-container',[_c('v-row',[_c('v-app-bar',{class:_vm.textColor,attrs:{"color":_vm.$mainColor,"height":_vm.size.appBar.height}},[_c('v-app-bar-nav-icon',{class:[_vm.textColor],on:{"click":function($event){_vm.drawer = !_vm.drawer}}}),_c('v-toolbar-title',{staticClass:"full-size flex-centered"},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('div',{staticClass:"flex-centered"},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"user-email flex-centered pr-8"},[_vm._v(_vm._s(_vm.getCurrentUser.email))])])],1)],1)],1),_c('v-row',[_vm._t("default")],2)],1),_c('v-navigation-drawer',{staticClass:"sidebar-menu",attrs:{"absolute":"","floating":"","temporary":"","width":400},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-row',{attrs:{"align":"end"}},[_c('v-col',{attrs:{"cols":"12","align":"end"}},[_c('v-icon',{staticClass:"mr-1 mt-1",attrs:{"size":32},on:{"click":function($event){_vm.drawer = false}}},[_vm._v(" mdi-close ")])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-list',{attrs:{"dense":""}},[_vm._l((_vm.items),function(ref,index){
var name = ref.name;
var icon = ref.icon;
var path = ref.path;
return _c('v-list-item',{key:index,staticClass:"py-0 px-2"},[_c('router-link',{attrs:{"to":path}},[_c('v-btn',{class:['full-size-width text-none','mb-2', _vm.getCurrentPath === path ? 'drawer-selected-button' : null],attrs:{"text":"","color":_vm.$mainColor,"outlined":_vm.getCurrentPath === path}},[_c('v-row',{staticClass:"pa-0 ma-0",attrs:{"align":"center"}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(icon)+" ")]),_vm._v(" "+_vm._s(name)+" ")],1)],1)],1)],1)}),_c('v-divider',{staticClass:"mx-4"}),_c('v-list',{staticClass:"py-0 px-2"},[_c('v-btn',{staticClass:"full-size-width text-none",attrs:{"text":"","color":"red"},on:{"click":_vm.logout}},[_vm._v(" "+_vm._s(_vm.logoutButton.text)+" ")])],1)],2)],1)],1)],1)],1),_c('v-footer',{staticClass:"ma-0",attrs:{"color":_vm.$mainColor,"id":"footer","height":_vm.size.footer.height,"padless":""}},[_c('v-row',{staticClass:"ma-0 pa-0"},[_c('div',{staticClass:"flex-centered white--text",attrs:{"id":"marquee-panel"}},[(_vm.marqueeData.length)?_c('marquee',{attrs:{"scrolldelay":"10","scrollamount":"5"}},_vm._l((_vm.marqueeData),function(ref,index){
var link = ref.link;
var date = ref.date;
var amount = ref.amount;
var currency = ref.currency;
return _c('span',{key:index},[(index)?_c('span',{staticClass:"pl-2"},[_vm._v("|")]):_vm._e(),_c('span',{staticClass:"pl-2"},[_vm._v("["+_vm._s(date)+"]")]),_c('span',{staticClass:"pl-2 font-weight-bold"},[_vm._v(_vm._s(link)+":")]),_c('span',{staticClass:"pl-2"},[_vm._v(_vm._s(amount))]),_c('span',[_vm._v(_vm._s(currency.toUpperCase()))])])}),0):_vm._e()],1),_c('global-logo'),_c('span',{staticClass:"version",attrs:{"id":"app-version"}},[_vm._v("v"+_vm._s(_vm.$appVersion))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }