import {allDimensions, allMetrics} from "@/utils/macros/google-analytics";

export default {
    methods: {
        wrapper(name) {
            return {
                name
            }
        }
    },
    computed: {
        getGlobalOSChartSettings() {
            return {
                dimensions: [
                    this.wrapper(allDimensions.operatingSystem),
                ],
                metrics: [
                    this.wrapper(allMetrics.activeUsers)
                ],
            }
        },
        getGlobalPhonesModelsChartSettings() {
            return {
                dimensions: [
                    this.wrapper(allDimensions.deviceCategory),
                    this.wrapper(allDimensions.mobileDeviceBranding),
                    this.wrapper(allDimensions.mobileDeviceMarketingName),
                    this.wrapper(allDimensions.deviceModel),
                    this.wrapper(allDimensions.mobileDeviceModel),
                    this.wrapper(allDimensions.operatingSystem),
                ],
                metrics: [
                    this.wrapper(allMetrics.activeUsers)
                ],
            }
        }
    }
}
