import {api} from "@/utils/macros/apiRoutes";
import store from "@/store";

export default {
    install(Vue, options) {

        const authFetch = async (endpoint, {
            token,
            method = 'POST',
            type = 'json',
            data = {}
        } = {}) => {
            const response = await fetch(process.env.VUE_APP_CLOUD_FUNCTIONS_ADDRESS + endpoint, {
                method: method,
                mode: 'cors',
                body: JSON.stringify({
                    token: token || store.getters["authentication/getCurrentUser"].token,
                    request: data
                })
            })

            const result = await response[type]()
            return result
        }

        Vue.prototype.$functions = {
            options,
            fetch: authFetch,
            endpoints: api
        }
    }
}
