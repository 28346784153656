<template>
  <layout-loader-view>
    <v-sheet>
      <v-container>
        <v-row>
          <v-app-bar
              :color="$mainColor"
              :class="textColor"
              :height="size.appBar.height"
          >
            <v-app-bar-nav-icon :class="[textColor]" @click="drawer = !drawer"></v-app-bar-nav-icon>
            <v-toolbar-title class="full-size flex-centered">
              <v-row justify="space-between">
                <div class="flex-centered">{{ title }}</div>
                <div class="user-email flex-centered pr-8">{{ getCurrentUser.email }}</div>
              </v-row>
            </v-toolbar-title>
          </v-app-bar>
        </v-row>
        <v-row>
          <slot></slot>
        </v-row>
      </v-container>

      <v-navigation-drawer
          v-model="drawer"
          absolute
          floating
          temporary
          :width="400"
          class="sidebar-menu"
      >
        <v-row align="end">
          <v-col cols="12" align="end">
            <v-icon
                :size="32"
                @click="drawer = false"
                class="mr-1 mt-1"
            >
              mdi-close
            </v-icon>
          </v-col>
          <v-col cols="12">
            <v-list dense>
              <v-list-item v-for="({name, icon, path}, index) in items" :key="index" class="py-0 px-2">
                <router-link :to="path">
                  <v-btn
                      text
                      :color="$mainColor"
                      :outlined="getCurrentPath === path"
                      :class="['full-size-width text-none','mb-2', getCurrentPath === path ? 'drawer-selected-button' : null]"
                  >
                    <v-row class="pa-0 ma-0" align="center">
                      <v-icon class="mr-1">
                        {{ icon }}
                      </v-icon>
                      {{ name }}
                    </v-row>

                  </v-btn>
                </router-link>
              </v-list-item>

              <v-divider class="mx-4"></v-divider>

              <v-list class="py-0 px-2">
                <v-btn
                    text
                    class="full-size-width text-none"
                    color="red"
                    @click="logout"
                >
                  {{ logoutButton.text }}
                </v-btn>
              </v-list>
            </v-list>
          </v-col>
        </v-row>
      </v-navigation-drawer>
    </v-sheet>
    <v-footer :color="$mainColor" id="footer" :height="size.footer.height" padless class="ma-0">
      <v-row class="ma-0 pa-0">
        <div id="marquee-panel" class="flex-centered white--text">
          <marquee scrolldelay="10" scrollamount="5" v-if="marqueeData.length">
            <span v-for="({link, date, amount, currency},index) in marqueeData" :key="index">
              <span class="pl-2" v-if="index">|</span>
              <span class="pl-2">[{{ date }}]</span>
              <span class="pl-2 font-weight-bold">{{ link }}:</span>
              <span class="pl-2">{{ amount }}</span>
              <span>{{ currency.toUpperCase() }}</span>
            </span>
          </marquee>
        </div>
        <global-logo></global-logo>
        <span id="app-version" class="version">v{{ $appVersion }}</span>
      </v-row>
    </v-footer>
  </layout-loader-view>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import GlobalLogo from "@/components/global/GlobalLogo";

export default {
  name: "LayoutDashboard",
  components: {
    GlobalLogo
  },
  data: () => ({
    marqueeData: [],
    textColor: 'white--text',
    drawer: false,
    logoutButton: {
      text: 'Logout'
    },
    size: {
      appBar: {
        height: 70
      },
      footer: {
        height: 100
      }
    },
    items: [
      {
        name: 'Overview',
        icon: 'mdi-view-dashboard',
        path: '/dashboard/overview'
      },
      {
        icon: 'mdi-chart-box',
        name: 'Statistics',
        path: '/dashboard/statistics'
      },
      {
        icon: 'mdi-shield-key',
        name: 'Administrators',
        path: '/dashboard/admins'
      },
      {
        icon: 'mdi-help-network-outline',
        name: 'Users activity',
        path: '/dashboard/users-activity'
      },
      {
        icon: 'mdi-credit-card-settings-outline',
        name: 'Payments stats',
        path: '/dashboard/payments-stats'
      }
    ]
  }),
  props: {
    title: {type: String},
  },
  mounted() {
    this.marqueeData = this.lastTransactions()
  },
  computed: {
    ...mapGetters('authentication', ['getCurrentUser']),
    ...mapGetters('statistics', ['getLastTransactions']),
    getCurrentPath() {
      return this.$router.currentRoute.path
    }
  },
  methods: {
    ...mapMutations('authentication', ['setPassedUser']),
    lastTransactions() {
      return this.getLastTransactions
    },
    async logout() {
      await this.$firebase.auth().signOut()
      this.setPassedUser(false)
      window.location = `${window.location.origin}/login`
    }
  },
  watch: {
    getLastTransactions(value) {
      this.marqueeData = value
    }
  }
}
</script>

<style>

#marquee-panel {
  height: 30px;
  font-size: 14px;
}

.drawer-selected-button {
  background-color: #E0DFE0;
}

.fill-container-height {
  min-height: calc(100vh - 170px);
}
</style>

<style scoped>

.version {
  position: absolute;
  bottom: 4px;
  right: 32px;
  font-size: 10px;
  color: white;
}

.container {
  max-width: none;
}

.user-email {
  font-size: 12px;
}
</style>

