import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)

export default new Vuetify({
    customVariables: ['~/theme/variables.scss'],
    treeShake: true,
    theme: {
        options: { customProperties: true },
        themes: {
            light: {
                primary: '#2C30DD',
                secondary: '#1D1D1B',
                secondaryDark: '#000000',
                error: '#B00020'
            },
            dark: {
                primary: '#2C30DD',
                secondary: '#1D1D1B',
                secondaryDark: '#000000',
                error: '#B00020'
            }
        }
    }
})
