import globalStore from '@/store'

const checkAuthentication = async function (token, next = () => {
}) {
    if (!globalStore.getters['authentication/getPassedUser']) { // IN CASE WE WANT ON EACH ROUTE CHECK DELETE THIS CONDITION
        const {authenticated} = await this._vm.$functions.fetch(this._vm.$functions.endpoints.checkAccess, {token})
        next(authenticated)
    } else {
        next(true)
    }
}

export default {
    isLogged(localStore) {
        return new Promise((resolve) => {
            this._vm.$firebase.auth().onAuthStateChanged(async (user) => {
                if (user) {
                    const token = await user.getIdToken(true)
                    await checkAuthentication.call(this, token, (parameter) => {
                        localStore.commit('setCurrentUser', {
                            uid: user?.uid,
                            authenticated: parameter,
                            token,
                            email: user?.email
                        })
                        resolve(parameter)
                    })
                } else {
                    resolve(false)
                }
            })
        })

    },
    async login(localStore, {email, password, next}) {
        try {
            await this._vm.$firebase
                .auth()
                .signInWithEmailAndPassword(email, password)
            next()
        } catch (_) {
            console.error('')
        } // TODO: IN FUTURE HANDLE THIS CASE
    }
}
