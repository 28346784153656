import moment from "moment";
import firebase from "firebase/app";

export default ({dateTime, payments}) => {
    try {
        dateTime = parseInt(dateTime)

        const searchDate = moment().set({
            seconds: 0,
            minutes: 0,
            hours: 0,
            milliseconds: 0
        }).subtract(dateTime, 'days')

        const searchTimestamp = firebase.firestore.Timestamp.fromDate(new Date(searchDate))

        return payments
            .filter(payment => payment.createTimestamp.seconds > searchTimestamp.seconds)
            .sort((a, b) => {
                return a.createTimestamp.seconds > b.createTimestamp.seconds ? -1 : 1
            })
            .map(payment => {
                const {createTimestamp, updateTimestamp, ...rest} = payment

                return {
                    ...rest,
                    createTimestamp: moment.unix(createTimestamp.seconds).format('DD-MM-YYYY'),
                    updateTimestamp: moment.unix(updateTimestamp.seconds).format('DD-MM-YYYY')
                }
            })
            .reduce((acc, {createTimestamp, amount, currency}) => {

                if (acc[createTimestamp]?.[currency]) acc[createTimestamp][currency] += amount / 100
                else acc[createTimestamp] = {
                    [currency]: amount / 100
                }

                return acc
            }, {})
    } catch (e) {
        console.error(e)
    }
}