export default {
    getAppOverview: (state) => state.appOverview,
    getAppStatistics: (state) => state.appStatistics,
    getLastTransactions: (state) => state.lastTransactions || [],
    getAppStatisticsFetching: (state) => state.appStatisticsFetching,
    getAverageDailyHoursTransactions: (state) => state.averageDailyHoursTransactions,
    getGlobalStatistics: (state) => state.globalStatistics,
    getCollectionsStatistics: (state) => state.collectionsStatistics,
    getCurrentCollection: (state) => state.currentCollection,
    getGlobalBlueMediaDonationProcess: (state) => state.globalBlueMediaDonationProcess,
    getGlobalAvgPaymentTime: (state) => state.globalAvgPaymentTime,
    getAllStickers: (state) => state.allStickers,
    getAllUsers: (state) => state.allUsersData,
    getAllPaymentsPerDay: (state) => state.allPaymentsPerDay,
    getAllPayments: (state) => state.allPayments,
    getWaitingOrders: (state) => state.waitingOrders,
}
