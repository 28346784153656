<template>
  <div class="full-size">
    <slot v-if="!getLoader"></slot>
    <loader v-else/>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import Loader from "@/components/global/Loader";

export default {
  name: "LoaderView",
  components: {
    Loader
  },
  computed: {
    ...mapGetters('components', ['getLoader'])
  }
}
</script>
