import store from '@/store'

export default {
    appOverview: undefined,
    appStatistics: [],
    appStatisticsFetching: true,
    lastTransactions: [],
    averageDailyHoursTransactions: {},
    globalStatistics: {},
    collectionsStatistics: {},
    currentCollection: undefined,
    firebaseReference(name) {
        return store._vm.$firebase.firestore().collection(name)
    },

    // GLOBAL
    allPayments: null,
    allPaymentsPerDay: {},
    globalBlueMediaDonationProcess: null,
    allStickers: null,
    allUsersData: null,
    waitingOrders: null,
    globalAvgPaymentTime: null
}
